import React from 'react';
import Layout from '../../components/layout';
import Seo from "../../components/seo";
import HeaderPage from '../../utils/HeaderPage';
import SideBar from '../../components/SideBar/SideBar';
import Parser  from 'react-html-parser'
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { isSSR, updatePageContent, todayStr } from '../../utils/utils';
const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDozMjc3Mw==") {
      id
      title
      content
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }    
    }
  }
}
  `
const BlockchainFintech = () => {
  const data = useStaticQuery(getdata);
  const common = data.wpgraphql.page;
  return (
    <Layout>
      <Seo title={common.title} description={common.seo.metaDesc} canonical={ `${common.uri}`} seo={common.seo}  />
      <HeaderPage headerTxt={'Industries'}></HeaderPage>
      <section className='mainSpacing2'>
        <div className="container industries-outr ps-0">
          <div className='row'>
            <div className='col-md-12'>
              <h2 className='display-5 mb-3 border-bottom d-inline-block pb-3'>{ common.title }</h2>
            </div>
          </div>
        </div>
        
        <div className="container ps-0">
          <div className='row'>
            <div className='col-md-9'>
              {Parser(isSSR() ? common.content : updatePageContent(common.content))}
            </div>
            <div className='col-md-3'>
              <SideBar></SideBar>
            </div>
          </div>
        </div>

        <div className="container ps-0">
          <div className='row'>
            <div className='col-md-12'>
              <p><small>
              Also checkout the latest patents on <Link to="/industries/3-d-printing/">3-D Printing</Link>, <Link to="/industries/electronics/">Electronics</Link>,
              &nbsp;<Link to="/industries/software/">Software</Link>, <Link to="/industries/communications/">Communication</Link>,  
              &nbsp;<Link to="/industries/artificial-intelligence-ai/">Artificial Intelligence (AI)</Link>,  
              &nbsp;<Link to="/industries/nanotechnology/">Nanotechnology</Link>, <Link to="/industries/autonomous-vehicles/">Autonomous Vehicles</Link>, and 
              &nbsp;<Link to="/industries/metaverse/">Metaverse</Link>
              </small>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlockchainFintech;
